body#yoyaku {
	.header__banner figure img{
		@include max-screen(767px) {
			width: 80%;
		}
		@include max-screen(480px) {
			width:230px;
		}
	}
	#main{
		background-color: #f2f2f2;
		color: #010100;
		.container{
			max-width: 920px;		
		}
		.sec01 .container{
			max-width: 830px;		
		}
		.sec03 .container{
			max-width: 700px;		
		}
		h2.title{
			color: #000101;
	        @include font-size(30);
	        text-align: center;
	        padding-bottom: 10px;
	        margin-bottom: 55px;
	        line-height: 1.3;
	        background: url(../images/recommend/line_blue.jpg) bottom center repeat-x;
	        max-width: 775px;
	        @include max-screen(767px) {
				@include font-size(15);
				padding-bottom: 20px;
	        	padding-top: 0px;
	        	margin-bottom: 14px;
			}
		}
		section{
			padding: 50px 0;
			@include max-screen(767px) {
				padding: 24px 0 30px !important;
			}
		}
		h3.title__border__01{
			color: #8fc31f;
			background-color: #fff;
	        @include font-size(30);
	        text-align: center;
	        padding: 12px 30px;
	        border: 1px solid;
	        width: 276px;
	        height: 55px;
	        @include border-radius(5px);
	        margin-bottom: 40px;
	        @include max-screen(767px) {
				@include font-size(15);
				margin-bottom: 20px !important;
	        	width: 140px;
	        	padding: 6px;
	        	height: 26px;

			}
			&._width2{
				width: 412px;
				@include max-screen(767px) {
					width: 205px;
				}
			}
		}
		.image__full{
			text-align: center;
			@include max-screen(767px) {
				width: 80%;
				margin: 0 auto;
				img{
					width: 100%;
				}
			}
		}
		p.text01{
			@include font-size(24);
	        text-align: center;
	        margin-bottom: 40px;
	        letter-spacing: 0px;
	        @include max-screen(767px) { 
	        	@include font-size(12);
	        	margin-bottom: 10px;
	        }
		}
		p.text02{
			@include font-size(24);
	        text-align: center;
	        margin-bottom: 10px;
	        letter-spacing: 0px;
	        @include max-screen(768px) {
	        	@include font-size(22);
	        	line-height: 1.5em;
	        }
	        @include max-screen(767px) { 
	        	@include font-size(12);
	        	margin-bottom: 10px;
	        	line-height: 1.3;
	        }
		}
		.row .col3{
			text-align: center;
			background: url(../images/yoyaku/vline_dot.png) right center no-repeat;
			padding: 40px 5px 0;
			@include max-screen(767px) { 
				padding-top: 0;
				margin-bottom: 20px;
				background: none;

			}
			.sec01__text{
				display: block;
				height: 60px;
				margin-top: 14px;
				@include max-screen(767px) { 
					height: auto;
					margin-top: 5px;
				}
				.text__middle{
					position: relative;
					top: 50%;
					-webkit-transform: translateY(-50%);
	                -ms-transform: translateY(-50%);
	                -o-transform: translateY(-50%);
	                transform: translateY(-50%);
					display: block;
					line-height: 1.5;
					@include font-size(15);
					@include max-screen(767px) { 
						@include font-size(12);
						top:0;
						-webkit-transform: none;
						-ms-transform: none;
						-o-transform: none;
						transform: none;
					}
				}
			}
			&:last-child{
				background: none;
				.sec01__text .text__middle{
					margin-top: 6px;
					text-align: left;
					@include font-size(13);
				}
				@include max-screen(767px) { 
					margin-bottom: 0;
				}
			}

		}

		.row .col6{
			text-align: center;
			padding: 0 20px;
			margin-bottom: 12px;
			background: url(../images/yoyaku/vline_dot2.png) right center no-repeat;
			&:last-child{
				background: none;
			}
			p{
				text-align:left;
				margin-bottom: 10px;
			}
			&::after{
				background: url(../images/yoyaku/hline_dot2.png) center center no-repeat;
				content: "";
				width: 100%;
				height: 7px;
				display: inline-block;
			}
			@include max-screen(767px) { 
				padding: 0 10px;
				background: none;
				&::after{
					background: none;
				}
			}
		}
		.row:last-child .col6{
			&::after{
				background: none;
			}
		}


		.sec01{
			background-color: #f2f2f2;
			padding: 50px 0 35px;
			.sec01__text01{
				max-width: 600px;
				margin: 0 auto 48px;
				line-height: 1.8;
				@include max-screen(767px) {
					margin-bottom: 24px;
				}

			}
			.sec01__text02{
				text-align: center;
				margin-top: 20px;
			}
			@include max-screen(767px) {
				&.sec01_01 .row{
					width: 60%;
					margin: 0 auto;
				}
			}
		}
		.sec02{
			background-color: #fffffe;
			padding: 60px 0 50px;
			.sec02__text01{
				max-width: 670px;
				margin: 0 auto 48px;
				line-height: 1.8;
				@include max-screen(767px) {
					margin-bottom: 24px;
				}

			}
			&.sec02_02{
				padding-bottom: 45px;
			}
			&.sec02_04{
				h3.title__border__01{
					margin-bottom: 30px;
				}
			}
			&.sec02_06{
				padding-bottom: 60px;
				p.text02{
					max-width: 817px;
					margin: 0 auto 32px;
					@include max-screen(767px) {
						text-align: left;
						margin-bottom: 12px;
					}
				}
				h3.title__border__01._width2{
					margin-bottom: 32px;
				}
			}
			
			@include max-screen(767px) {
				
			}
		}
		.sec03{
			padding: 50px 0 30px;
			@include max-screen(767px) {
				padding: 24px 0 0 !important;
				&.sec03_03,&.sec03_05{
					.row{
						margin: 0 auto;
						width: 70%;
						@include max-screen(480px) {
							width: 100%;
						}
						.col6{
							p{
								line-height: 1.5;
								display: inline-block;
							}
						}
					}
				}
				
			}
			.text01{
				margin-bottom: 6px;
				@include max-screen(767px) {
					margin-bottom: 18px;
				}
			}
			
			.image__middle{
				height: 142px;
				margin-bottom: 17px;
				img{
					position: relative;
					top: 50%;
					-webkit-transform: translateY(-50%);
	                -ms-transform: translateY(-50%);
	                -o-transform: translateY(-50%);
	                transform: translateY(-50%);
					display: block;
					margin: 0 auto;
				}
				@include max-screen(767px) {
					height: auto;
					margin-bottom: 22px;
					img{
						top: auto;
						-webkit-transform: none;
						-ms-transform: none;
						-o-transform: none;
						transform: none;
					}
				}
			}
			&.sec03_05{
				padding-bottom: 20px;
				.text01{
					margin-bottom: 14px;
					@include max-screen(767px) {
						margin-top: 0px;
					}
				}
				.row .col6 p{
					padding-left: 15px;
				}
			}
		}
		@include max-screen(767px) {
			.__image01{
				width: 106px;
			}	
			.__image02{
				width: 105px;
			}
			.__image03{
				width: 115px;
			}
			.__image04{
				width: 162px;
				margin-left: 15px;
			}
			.__image05{
				width: 164px;
			}	
			.__image06{
				width: 163px;
			}
			.__image07{
				width: 163px;
			}
			.__image08{
				width: 134px;
			}
			.__image09{
				width: 127px;
			}
			.__image10{
				width: 154px;
			}
		}
	}
	.row{
		margin-left: -15px;
		margin-right: -15px;
	}
	.row::after {
		display: block;
		content: "";
		clear: both;
	}
	.col3{
		width: 25%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 100%;
		}
	}
	.col6{
		width: 50%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 100%;
		}
	}
	.hide_br_pc{
		br{
			display:none;
			@include max-screen(767px) {
				display: block;
			}
		}

	}
	
	
}
