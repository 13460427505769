#contact {
    .content {
        padding: 60px 0 50px;
        max-width: 800px;
        margin: 0 auto;
        @include max-screen(767px) {
            padding: 30px 0;
        }
        h2 {
            @include font-size(30);
            text-align: center;
            margin: 0 0 25px;
            @include max-screen(767px) {
                @include font-size(15);
                margin: 0 0 15px;
            }
        }
        .txt {
            @include font-size(15);
            text-align: left;
            color: #1d2088;
            max-width: 410px;
            margin: 0 auto;
            padding: 0 0 0 40px;
            position: relative;
            line-height: 1.66em;
            span.center__block{
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                display: inline-block;
                margin: 0 auto;
                position: relative;
            }
            @include max-screen(767px) {
                @include font-size(12);
                line-height: 1.35em;
                text-align: left;
                margin: 0 auto;
                padding-left: 0px;
            }
        }
        h3 {
        	background-color: #8fc31f;
        	@include font-size(15);
        	color: #fff;
        	text-align: left;
        	padding: 15px 30px;
        	margin: 0 0 20px;
        	@include max-screen(767px) {
        		@include font-size(12);
        		margin: 0 -15px;
        		padding: 12px 15px;
        		line-height: 1.58em;
        		margin: 0 0 10px;
        	}
        }
        .sec01 {
        	padding: 0 0 60px;
            .container{
                text-align: center;
                p.txt{
                    display: inline-block;
                }
            }
            @include max-screen(767px) {
                padding: 0 0 50px;
            }
            ul {
            	padding: 30px 0 0;
                li {
                    text-align: center;
                    @include font-size(15);
                    text-align: center;
                    color: #1d2088;
                    margin: 0 0 5px;
                    display: block;
                    &:last-child {
                    	span {
                    		display: inline-block;
                    		padding: 0 5px;
                    	}
                    }
                }
                @include max-screen(767px) {
                    padding: 22px 0 0;
                    li{
                        width: 275px;
                        margin: 0 auto 5px;
                        @include font-size(12);
                        span{
                            float:left;
                            &.__time{
                                img{width:95px;}
                            }
                            &.__tel{
                                img{width:160px;}
                            }
                        }
                    }
                }
            }
        }
        .sec02 {
        	max-width: 800px;
        	margin: 0 auto;
            @include max-screen(767px) {
                margin: 0 -15px;
            }
        	&__wrap {
        		@include font-size(15);
        		padding: 0 10px;
        		.required {
        			color: #ff0000;
        		}
        		p {
        			margin: 0 0 20px 15px;
                    @include max-screen(767px) {
                        margin: 0 0 10px 5px;
                    }
        		}
        		.contact_form {
        			
                    .box__01{
                        border: 1px solid #000000;
                        @include border-radius(3px);
                        padding: 20px 15px;
                        background-color: #fff;
                        @include max-screen(767px) {
                            margin: 0 0 10px;
                            padding: 15px 6px;
                            @include font-size(12);
                        }
                    }
                    dl {
                        padding: 10px;
                        @include pie-clearfix();
                        display: table;
                        width: 100%;
                        &:nth-child(odd) {
                            background-color: #e5e5e5;
                        }
                        @include max-screen(767px) {
                            padding: 5px 10px;
                            margin-bottom: 5px;
                            min-height: 70px;
                        }
                        dt {
                            display: table-cell;
                            vertical-align: middle;
                            width: 32%;
                            @include max-screen(767px) {
                                width: 100%;
                                display: block;
                                margin-bottom: 3px;
                            }
                        }
                        dd {
                            display: table-cell;
                            width: 68%;
                            @include max-screen(767px) {
                                width: 100%;
                                display: inline-block;
                            }
                            &.dd__radio1{
                                span{
                                    display:block;
                                    line-height: 1.8;
                                    input{
                                        margin-right: 10px;
                                        width: 15px;
                                        height: 15px;
                                        box-shadow: inset 1px 1px 0px #000; 
                                        border-radius: 999px;
                                        -webkit-appearance: inherit;
                                        border:1px solid #000;
                                        position:relative;
                                        box-sizing:border-box;
                                        position: relative;
                                        top: 5px;
                                        &:checked:before{
                                            content:"";
                                            position:absolute;
                                            border-radius: 999px;
                                            left:25%;
                                            top:25%;
                                            width:50%;
                                            height:50%;
                                            background:#8fc31f;
                                        }
                                        @include max-screen(767px) {
                                            margin-right: 5px;
                                            position: relative;
                                            margin-left: 10px;

                                        }
                                    }
                                }
                            }
                            textarea{
                                width: 100%;
                                height: 146px;
                                @include border-radius(3px);
                                webkit-box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                                -moz-box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                                box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                                overflow: auto;
                                border: 1px solid;
                                padding-left: 5px;
                                padding-top: 5px;
                                @include max-screen(767px) {
                                     height: 100px;
                                }
                            }
                            
                        }

                        &.dl__custom1{
                            padding-top: 0;
                            @include max-screen(767px) {
                                margin-top: -5px;
                                padding-top: 5px;
                            }
                            dt{
                                vertical-align: text-top;
                            }
                        }
                    }
                    .input_text {
                        display: block;
                        width: 100%;
                        height: 30px;
                        @include border-radius(3px);
                        border: 1px solid #000;
                        webkit-box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                        -moz-box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                        box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                        padding-left: 5px;
                        @include max-screen(767px) {
                            height: 22px;
                        }
                    }
                    .text_after {
                        color: #aaaaaa;
                        display: inline-block;
                        padding: 5px 0 0;
                        .sp_break_line{
                            @include max-screen(767px) {
                                display: block;
                                padding-left: 22px;
                                line-height: 18px;
                            }
                        }
                    }
                    .slc_contact {
                        @include border-radius(3px);
                        border: 1px solid #000;
                        width: 40%;
                        height: 30px;
                        @include font-size(15);
                        webkit-box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                        -moz-box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                        box-shadow: inset 1px 1px 1px 0px rgba(0,0,0,0.75);
                        @include max-screen(767px) {
                            height: 22px;
                            @include font-size(12);
                            width: 100%;
                        }
                    }
                    .submit_btn{
                        width: 490px;
                        height: 60px;
                        display: block;
                        line-height: 60px;
                        margin: 50px auto 0px;
                        color: #fff;
                        padding: 0px 20px;
                        background-color: #f19149;
                        text-align: center;
                        border: none;
                        -webkit-appearance: none;
                       -moz-appearance:    none;
                       appearance:         none;
                        @include border-radius(0px);
                        @include font-size(20);
                        @include max-screen(767px) {
                            max-width:490px;
                            width: 90%;
                            height: 36px;
                            line-height: 36px;
                            @include font-size(16);
                            margin: 18px auto 0px;
                        }
                    }
        		}
        	}
        }
    }
}

#contact.contact2{
    .content .sec02__wrap .contact_form dl{
        min-height: 70px;
        @include max-screen(767px) {
            min-height: 65px;
        }
        dd{
            vertical-align:middle;
            @include max-screen(767px) {
                padding: 7px 7px 0 7px;
            }
            p{
                margin-bottom: 0;
                @include font-size(24);
                @include max-screen(767px) {
                    @include font-size(15);
                    line-height: 1.5;
                }
            }

        }
        &.dl__custom1{
            dt{
                position:relative;
                top: 10px;
            }
        }
        &.dl_custom2{
            min-height: 140px;
        }
    }
}

#contact.contact3{
    background-color: #f9f9f9;
    .content{ 
        padding: 60px 0 30px;
         @include max-screen(767px){
            padding-bottom: 0;
         }
        .sec02__wrap{
            margin: 36px 15px;
            padding: 40px 28px 10px; 
            background-color: #fff;
            p{
                margin-bottom:30px;
                line-height: 2;
                @include max-screen(767px){
                    margin-bottom:24px;
                    line-height: 1.6;
                }
            }
            @include max-screen(767px){
                margin: 20px 5px;
                padding: 22px 16px 10px;
            }
        }
    }
    h4{
        @include font-size(24);
        margin-bottom: 24px;
        @include max-screen(767px) {
            @include font-size(15);
            margin-bottom: 20px;
        }
    }
}