@charset 'UTF-8';
.footer {
    &__top {
        background-color: #f2f2f2;
        padding: 35px 0 45px;
        .container{
            text-align: center;
            .footer__top__txt{
                display: inline-block;
            }
        }
        @include max-screen(767px) {
            padding: 20px 0;
        }
        &__txt {
            color: #1d2088;
            @include font-size(15);
            text-align: left;
            line-height: 1.66em;
            max-width: 400px;
            margin: 0 auto 20px;
            position: relative;
            span.center__block{
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                margin: 0 auto;
                position: relative;
            }
            @include max-screen(767px) {
                @include font-size(12);
                margin: 0 auto 10px;
                width: 90%;
                @include max-screen(320px) {
                    width: 100%;
                }
            }
        }
        .btn {
            max-width: 490px;
            margin: 0 auto 20px;
            color: #fff;
            padding: 18px 0;
            background-color: #f19149;
            text-align: center;
            @include font-size(24);
            @include max-screen(767px) {
                @include font-size(16);
            }
            &:hover {
                background-color: #ffab11;
                opacity: 1;
            }
        }
        &__utm {
            margin: 0 auto;
            max-width: 365px;
            @include pie-clearfix();
            @include max-screen(767px) {
                padding: 0 20px;
            }
            li {
                float: left;
                @include max-screen(767px) {
                    width: 35%;
                }
                &:last-child {
                    padding-left: 20px;
                    @include max-screen(767px) {
                        width: 65%;
                        padding-left: 10px;
                    }
                }
                a {
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__bottom {
        background-color: #8fc31f;
        @include pie-clearfix();
        width: 100%;
        &--item {
            max-width: 575px;
            margin: 0 auto;
            @include pie-clearfix();
            .f_logo {
                vertical-align: middle;
                display: inline-block;
                float: left;
                padding: 15px 0 0;
                @include max-screen(767px) {
                    width: 40%;
                }
            }
            .f_link {
                text-align: right;
                float: right;
                vertical-align: middle;
                display: inline-block;
                padding: 40px 0 32px;
                @include max-screen(767px) {
                    padding: 20px 0;
                    width: 50%;
                }
                li {
                    text-align: left;
                    float: left;
                    @include max-screen(767px) {
                        float: none;
                        display: block;
                        padding: 0 0 10px;
                    }
                    &:last-child {
                        padding-left: 40px;
                        @include max-screen(767px) {
                            padding: 0;
                        }
                    }
                    a {
                        color: #fff;
                        border-left: 1px solid #fff;
                        padding-left: 10px;
                        &:hover {
                            color: #008c40;
                            border-left: 1px solid #008c40;
                        }
                        @include max-screen(767px) {
                            @include font-size(11);
                        }
                    }
                }
            }
        }
    }
    .f_copyright {
        background-color: #45ada8;
        text-align: center;
        margin: 0;
        padding: 6px 0 10px;
        color: #fff;
        @include font-size(12);
        @include max-screen(767px) {
            @include font-size(9);
            line-height: 1.3em;
            padding: 10px 0;
        }
    }
}
