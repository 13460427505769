#privacy {
    .content {
        padding: 60px 0 30px;
        max-width: 800px;
        margin: 0 auto;
        @include max-screen(767px) {
        	padding: 30px 0;
        }
        h2 {
            @include font-size(30);
            text-align: center;
            margin: 0 0 25px;
            @include max-screen(767px) {
            	@include font-size(15);
            	margin: 0 0 15px;
            }
        }
        .txt {
            @include font-size(18);
            text-align: center;
            @include max-screen(767px) {
            	@include font-size(14);
            	line-height: 1.35em;
            	text-align: left;
            	max-width: 250px;
            	margin: 0 auto;
            }
        }
        h3 {
        	background-color: #8fc31f;
        	@include font-size(15);
        	color: #fff;
        	text-align: left;
        	padding: 15px 30px;
        	@include max-screen(767px) {
        		@include font-size(12);
        		margin: 0 -15px;
        		padding: 12px 15px;
        		line-height: 1.58em;
        	}
        }
        .box_txt {
        	background-color: #fff;
        	@include font-size(15);
        	margin: 30px 0;
        	padding: 20px 15px;
        	min-height: 90px;
        	@include max-screen(767px) {
        		@include font-size(12);
        		min-height: auto;
        		margin: 15px 0;
        		padding: 15px 10px;
        	}
        	span {
        		display: block;
        		text-align: center;
        		&:last-child {
        			position: relative;
        			left: -10px;
        		}
        	}
        }
        .sec01 {
        	padding: 55px 0 30px;
        	@include max-screen(767px) {
        		padding: 30px 0 15px;
        	}
        	dl {
        		margin: 30px 0;
        		padding: 0 30px;
        		@include max-screen(767px) {
        			margin: 15px 0;
        			padding: 0;
        		}
        		dt {
        			background-color: #e5e5e5;
        			padding: 17px 15px;
        			@include font-size(15);
        			@include max-screen(767px) {
        				@include font-size(12);
        				padding: 15px 10px;
        				line-height: 1.58em;
        			}
        		}
        		dd {
        			background-color: #fff;
        			padding: 20px 15px;
        			@include font-size(15);
        			line-height: 1.66em;
        			@include max-screen(767px) {
        				@include font-size(12);
        				padding: 10px 10px 20px;
        				line-height: 1.58em;
        			}
        		}
        	}
        }
        .sec02{
            .box_txt{
                margin: 30px 30px 60px;
                a{
                    display: inline-block;
                }
                @include max-screen(768px) {
                    margin: 30px 30px 60px;
                }
                @include max-screen(767px) {
                    span{
                        text-align: left;
                        left: 0;
                    }
                    margin: 15px 0 30px;
                }
            }
        }
        .sec03{
            .box_txt{
                margin: 30px;
                @include max-screen(767px) {
                    margin: 15px 0 5px;
                    padding: 15px 10px 25px;
                }
            }
        }
    }
}
