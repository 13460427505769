#how_to_use {
    .header__banner {
        figure {
            img {
                @include max-screen(767px) {
                    width: 80%;
                }
            }
        }
    }
    .sec01 {
        padding: 60px 0 60px;
        @include max-screen(767px) {
            padding: 30px 0 20px;
        }
        h2 {
            @include font-size(30);
            text-align: center;
            border-bottom: 3px solid #79c3ed;
            padding: 0 0 5px;
            margin: 0 auto 30px;
            max-width: 775px;
            @include max-screen(767px) {
                @include font-size(16);
                margin: 0 0 15px;
                border-bottom: 2px solid #79c3ed;
            }
        }
        &__txt {
            @include font-size(20);
            padding: 0 0 0 100px;
            margin-bottom: 10px;
            @include max-screen(768px) {
                @include font-size(14);
                padding: 0 0 0 8%;
            }
            @include max-screen(767px) {
                @include font-size(14);
                padding: 0 15px;
            }
        }
        &__table {
            background-color: #fff;
            padding: 25px 60px;
            max-width: 840px;
            margin: 0 auto;
            @include max-screen(767px) {
                padding: 20px 15px;
            }
            p {
                @include font-size(20);
                margin: 0 0 7px;
                @include max-screen(767px) {
                    @include font-size(14);
                    margin: 0 0 5px;
                    line-height: 1.35em;
                }
                span {
                    @include font-size(16);
                    padding: 0 0 0 15px;
                    @include max-screen(768px) {
                        display:block;
                        padding:0;
                    }
                    @include max-screen(767px) {
                        @include font-size(10);
                        display: inline;
                        padding: 0 0 0 5px;
                    }
                }
            }
            &__wrap {
                @include pie-clearfix();
                dl {
                    @include pie-clearfix();
                    float: left;
                    width: 50%;
                    border: 2px solid #c3d824;
                    border-top: transparent;
                    &:nth-child(odd) {
                        border-right: transparent;
                        @include max-screen(767px) {
                            border-right: 1px solid #c3d824;
                        }
                    }
                    &:first-child,
                    &:nth-child(2) {
                    	border-top: 2px solid #c3d824;
                    	@include max-screen(767px) {
                    		border-top: 1px solid #c3d824;
                    	}
                        dt {
                            display: block;
                        }
                        dd{
                            border-top: 2px solid #c3d824;
                            margin-top: 2px;
                            @include max-screen(767px) {
                                margin-top: 0px;
                            }
                        }
                    }
                    &:nth-child(4n),
                    &:nth-child(4n-1) {
                    	dd {
                    		background-color: #dbdbdb;
                    	}
                    }
                    @include max-screen(767px) {
                        float: none;
                        width: 100%;
                        border: 1px solid #c3d824;
                        &:nth-of-type(2n+2) {
                            margin: 0 0 20px;
                            border-bottom: 1px solid #c3d824;
                        }
                        &:last-child {
                            margin: 0;
                        }
                    }
                    dt {
                        background-color: #8fc31f;
                        color: #fff;
                        @include font-size(15);
                        padding: 13px 15px;
                        display: none;
                        @include max-screen(768px) {
                            display: block;
                            @include font-size(12);
                            padding: 10px;
                        }
                    }
                    dd {
                        padding: 13px 15px;
                        @include font-size(15);
                        @include max-screen(768px) {
                            @include font-size(12);
                            padding: 10px;
                            border-top: 1px solid #c3d824;
                        }
                    }
                }
            }
            .table_txt {
                @include font-size(16);
                margin: 10px 0 0 18px;
                @include max-screen(767px) {
                    @include font-size(10);
                }
            }
        }
    }
    .sec_bgwhite {
        background-color: #fffffe;
        padding: 50px 0 10px;
        @include max-screen(767px) {
            padding: 25px 15px 10px;
        }
        .sec_bg {
            background-color: #f2f2f2;
            max-width: 900px;
            margin: 0 auto 45px;
            padding: 25px 30px 40px;
            @include max-screen(767px) {
                padding: 20px 10px 20px;
                margin: 0 auto 20px;
            }
            .container {
                padding: 0;
            }
            h3 {
                @include font-size(20);
                text-align: left;
                line-height: 1.5em;
                border-bottom: 2px solid #c3d824;
                padding: 0 0 15px;
                margin: 0 0 15px;
                @include max-screen(767px) {
                    @include font-size(12);
                    margin: 0 0 10px;
                    padding: 0 0 10px;
                    letter-spacing: -0.5px;
                }
            }
            p {
                @include font-size(17);
                margin: 0 0 25px;
                @include max-screen(767px) {
                    @include font-size(10);
                    margin: 0 0 15px;
                    line-height: 1.5em;
                }
            }
            .list_2_img {
                text-align: center;
                li {
                    display: inline-block;
                    padding: 0 16px;
                    width: 49.5%;
                    vertical-align: top;
                    @include max-screen(767px) {
                        display: block;
                        width: 70%;
                        padding: 0;
                        text-align: center;
                        margin: 0 auto 10px;
                    }
                    span {
                        @include font-size(16);
                        text-align: left;
                        display: block;
                        letter-spacing: 0;
                        line-height: 1.58em;
                        padding: 20px 0 0 30px;
                        @include max-screen(767px) {
                            @include font-size(10);
                            line-height: 1.5em;
                            padding: 10px 0 0 15px;
                        }
                        &.pd_l {
                            padding-left: 60px;
                            @include max-screen(767px) {
                                padding-left: 30px;
                            }
                        }
                    }
                }
            }
        }
        .sec02 {
            p {
                margin: 0 0 10px;
            }
        }
        .sec04{
            @include max-screen(768px) {
                .hidden-xs{
                    display: none;
                }
            }
        }
        .sec05{
            figure img{
                width: 100%;
                @include max-screen(767px) {
                    width: auto;
                }
            }
        }
        .sec06 {
            .list_2_img {
                margin: 0 -16px;
            }
            @include max-screen(768px) {
                
            }
            @include max-screen(767px) {
                br{
                    display: inline-block;
                }
            }
        }
        .box {
            @include pie-clearfix();
            max-width: 900px;
            margin: 0 auto;
            &__wrap {
                @include pie-clearfix();
                margin: 0 -10px;
                @include max-screen(767px) {
                    margin: 0;
                }
                &--item {
                    width: 50%;
                    float: left;
                    padding: 0 10px;
                    @include max-screen(767px) {
                        width: 100%;
                        float: none;
                        padding: 0;
                    }
                    figure {
                        text-align: center;
                    }
                    p {
                        margin: 0 0 25px;
                    }
                }
            }
        }
    }
}
