body#uketsuke {
	.header__banner figure img{
		@include max-screen(767px) {
			width: 80%;
		}
		@include max-screen(480px) {
			width:230px;
		}
	}
	#main{
		background-color: #f2f2f2;
		color: #010100;
		.container{
			max-width: 920px;		
		}
		.sec00 .container{
			max-width: 775px;
		}
		.sec01 .container{
			max-width: 820px;
		}
		.sec04 .container{
			max-width: 950px;
		}
		@include max-screen(767px) {
			.sec01 .container,.sec02 .container,.sec03 .container{
				padding:0 10%;
			}
			.sec04 .container{
				width: 200px;
				padding: 0;
			}
		}
		h2.title{
			color: #000101;
	        @include font-size(30);
	        text-align: center;
	        background: url(../images/recommend/line_blue.jpg) bottom center repeat-x;
	        padding-bottom: 22px;
	        padding-top: 60px;
	        margin-bottom: 7px;
	        @include max-screen(767px) {
				@include font-size(15);
				padding-bottom: 20px;
	        	padding-top: 25px;
	        	margin-bottom: 0;
			}
			@include max-screen(320px) {
				@include font-size(13);
			}
		}
		h3.title__border{
			color: #8fc31f;
			background-color: #fff;
	        @include font-size(30);
	        text-align: center;
	        padding: 12px;
	        border: 1px solid;
	        width: 400px;
	        @include border-radius(5px);
	        margin-bottom: 50px;
	        @include max-screen(767px) {
				@include font-size(15);
				margin-bottom: 20px;
	        	width: 206px;
	        	padding: 6px;
			}
		}
		p{
			position: relative;
			span.center__block{
				left: 50%;
	            -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
	            display: inline-block;
	            margin: 0 auto;
	            position: relative;
	        }
		}
		.sec01{
			padding-bottom: 50px;
			padding-top: 58px;
			background-color: #f2f2f2;
			.sec01__text01{
				max-width: 640px;
				margin: 0 auto 48px;
				line-height: 1.8;
				@include font-size(15);
				@include max-screen(767px) {
					margin-bottom: 24px;
					@include font-size(12);
				}

			}
			.sec01__text02{
				text-align: center;
				margin-top: 20px;
				display: block;
			}
			@include max-screen(767px) {
				padding-bottom: 10px;
				padding-top: 30px;
				.row .col3{
					width: 100%;
					text-align: center;
					margin-bottom: 25px;
				}
			}
		}
		.sec02{
			padding-bottom: 50px;
			padding-top: 58px;
			background-color: #fffffe;
			.sec02__text01{
				max-width: 680px;
				margin: 0 auto 48px;
				line-height: 1.8;
				@include font-size(15);
				@include max-screen(767px) {
					margin-bottom: 24px;
					@include font-size(12);
				}

			}
			.row .col3{
				position: relative;
			}
			.row .col3::after{
				background: url(../images/uketsuke/arrow_organe.png) 0 0 no-repeat;
				content: "";
				width: 18px;
				height: 21px;
				top: 100px;
				right: -8px;
				position: absolute;
				display: inline-block;
			}
			.row .col3:last-child::after{
				background: none;
			}
			@include max-screen(767px) {
				padding-bottom: 10px;
				padding-top: 30px;
				.row .col3{
					width: 100%;
					text-align: center;
					margin-bottom: 25px;
					img{
						width: 150px;
					}
				}
				.row .col3::after{
					background: url(../images/uketsuke/arrow_organe2.png) 0 0 no-repeat;
					background-size: 100%;
					content: "";
					width: 24px;
					height: 24px;
					top: auto;
					bottom: -24px;
					left: 50%;
					position: absolute;
					display: inline-block;
				}
			}
		}
		.sec03{
			padding-bottom: 30px;
			padding-top: 58px;
			background-color: #f2f2f2;
			.sec03__text01{
				max-width: 680px;
				margin: 0 auto 48px;
				line-height: 1.8;
				@include font-size(15);
				@include max-screen(767px) {
					margin-bottom: 24px;
					@include font-size(12);
				}

			}
			.row .col6:first-child{
				text-align: right;
				img{
					margin-right: -33px;
					margin-top: 18px;
				}
			}
			
			@include max-screen(767px) {
				padding-bottom: 10px;
				padding-top: 30px;
				.row .col6{
					width: 100%;
					text-align: center !important;
					margin-bottom: 25px;
					img{
						width: 165px;
						margin: auto !important;
					}
				}
				
			}
		}
		.sec04{
			padding-bottom: 50px;
			padding-top: 58px;
			background-color: #fffffe;
			@include max-screen(767px) {
				padding-bottom: 30px;
				padding-top: 40px;
			}
			h3.title__2{
				color: #000101;
		        @include font-size(30);
		        text-align: center;
		        background: url(../images/recommend/line_blue.jpg) bottom center repeat-x;
		        padding-bottom: 15px;
		        margin-bottom: 48px;
		        width: 300px;
		        @include max-screen(767px) {
					@include font-size(15);
					padding-bottom: 8px;
		        	margin-bottom: 0;
		        	width: 165px;
				}
			}
			.row{
				margin-right: -3px;
				margin-left: -3px;
				.col3{
					padding-left: 3px;
					padding-right: 3px;
					@include max-screen(767px) {
						margin-top: 25px;
						width: 200px;
					}
					.sec04__boxgrey{
						background-color: #f2f2f2;
						padding: 0;
						height: 100%;
						@include max-screen(767px) {
							padding-bottom: 20px;
						}
						h4{
							@include font-size(20);
							line-height: 18px;
							color: #8fc31f;
							background: url(../images/uketsuke/arrow_grey.png) center bottom no-repeat;
							height: 74px;
							margin-bottom: 15px;
							.text__middle{
								position: relative;
								top: 50%;
								-webkit-transform: translateY(-50%);
				                -ms-transform: translateY(-50%);
				                -o-transform: translateY(-50%);
				                transform: translateY(-50%);
								display: block;
								span{
									position: relative;
									left: 26px;
									display: block;
									@include max-screen(767px) {
										left: 21px;
									}
								}
							}
							@include max-screen(995px) {
								@include font-size(15);
							}
							@include max-screen(767px) {
								@include font-size(16);
								margin-bottom: 10px;
								background: url(../images/uketsuke/arrow_grey.png) center bottom no-repeat;
								background-size: 90%;
							}

						}
						.boxgrey__title1{
							padding-left: 28px;
							padding-right:5px;
						}
						.boxgrey__title2{
							padding-left: 42px;
							padding-right:5px;
						}
						.boxgrey__title3{
							padding-left: 27px;
							padding-right:5px;
						}
						.boxgrey__title4{
							padding-left: 52px;
							padding-right:5px;
						}
						p{
							padding: 0 20px;
							text-align: justify;
						}
						.boxgrey__image{
							text-align: center;
							margin-top: 25px;
							img{
								display: block;
								margin: 0 auto;
							}
						}
					}

				}
			}
			
		}
	}
	.row{
		margin-left: -15px;
		margin-right: -15px;
	}
	.row::after {
		display: block;
		content: "";
		clear: both;
	}
	.col3{
		width: 25%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 100%;
		}
	}
	.col6{
		width: 50%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 100%;
		}
	}

	
}
