@charset 'UTF-8';
.header {
	background: #fffffe;

	&__info {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		@include pie-clearfix();
		@include max-screen(768px) {
			background-color: #f7f7f7;
			border-bottom: 1px solid #d5d5d5;
			height: 60px;
			position: fixed;
			z-index: 9999;
		}
		.logo {
			float: left;
			padding: 15px 0 0;
			margin: 0 0 0 -10px;
			@include max-screen(768px) {
				padding: 5px 0 0 10px;
			}
		}
	}
	&__utm {
		float: right;
		@include max-screen(768px) {
			display: none;
		}
		li {
			float: left;
			a {
				&:hover {
					opacity: 1;
				}
			}
		}
	}
	&__banner {
		text-align: center;
		min-height: 420px;
		height: 420px;
		display: table;
		width: 100%;
		@include max-screen(767px) {
			min-height: 270px;
			height: 270px;
		}
		figure {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			margin: 0 auto;
			padding: 75px 0 0;
			img {
				@include max-screen(767px) {
					width: 40%;
				}
			}
		}
	}
}