// Author: Kyohei Maeda
//==================================================
@charset 'UTF-8';
@font-face {
  font-family: 'BlackJackRegular';
  src: url('../fonts/BlackJackRegular/BlackJackRegular.eot?#iefix') format('embedded-opentype'),  url('../fonts/BlackJackRegular/BlackJackRegular.woff') format('woff'), url('../fonts/BlackJackRegular/BlackJackRegular.ttf')  format('truetype'), url('../fonts/BlackJackRegular/BlackJackRegular.svg#BlackJackRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CaviarDreams';
  src: url('../fonts/CaviarDreams/CaviarDreams.eot?#iefix') format('embedded-opentype'),  url('../fonts/CaviarDreams/CaviarDreams.woff') format('woff'), url('../fonts/CaviarDreams/CaviarDreams.ttf')  format('truetype'), url('../fonts/CaviarDreams/CaviarDreams.svg#CaviarDreams') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CenturyGothic';
  src: url('../fonts/CenturyGothic/CenturyGothic.eot?#iefix') format('embedded-opentype'),  url('../fonts/CenturyGothic/CenturyGothic.woff') format('woff'), url('../fonts/CenturyGothic/CenturyGothic.ttf')  format('truetype'), url('../fonts/CenturyGothic/CenturyGothic.svg#CenturyGothic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import 'compass_lib/compass'; //compass
@import 'compass_lib/compass/reset'; //compass reset
@import 'utility'; // utility
@import 'plugins'; // plugins
@import "/Users/yamagamishuji/Sites/checkon/src/scss/layout/_base.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/layout/_breadcrumb.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/layout/_footer.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/layout/_header.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/layout/_nav.scss";

@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_company.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_contact.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_howtouse.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_privacy.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_recommend.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_top.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_uketsuke.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_vaccine.scss";
@import "/Users/yamagamishuji/Sites/checkon/src/scss/page/_yoyaku.scss";

