body#vaccine {
	.header__banner figure img{
		&.hidden-xs {
			display: inline-block;
			@include max-screen(767px){
				display: none;
			}
		}
		&.visible-xs {
			display: none;
			@include max-screen(767px){
				display: inline-block;
			}
		}
		@include max-screen(767px) {
			width: 80%;
		}
		@include max-screen(480px) {
			width:230px;
		}

	}
	#main{
		background-color: #f2f2f2;
		color: #010100;
		.container{
			max-width: 920px;		
		}
		h2.title{
			color: #000101;
	        @include font-size(30);
	        text-align: center;
	        padding-bottom: 18px;
	        line-height: 1.3;
	        background: url(../images/recommend/line_blue.jpg) bottom center repeat-x;
	        max-width: 775px;
	        @include max-screen(767px) {
				@include font-size(15);
	        	padding-top: 0px;
	        	margin-bottom: 0px;
	        	padding-bottom:12px;
			}
		}
		section{
			padding: 50px 0;
			@include max-screen(767px) {
				padding: 24px 0;
			}
		}
		h3.title__border__01{
			color: #8fc31f;
			background-color: #fff;
	        @include font-size(30);
	        text-align: center;
	        padding: 12px 10px;
	        border: 1px solid;
	        width: 725px;
	        height: 55px;
	        @include border-radius(5px);
	        margin-bottom: 50px;
	        @include max-screen(767px) {
				@include font-size(15);
				margin-bottom: 20px !important;
	        	width: 80%;
	        	padding: 6px;
	        	height: auto;
				@include border-radius(3px);
			}
			&.__width02{
				width: 542px;
				@include max-screen(767px) {
					width: 96%;
					height: auto;
				}
			}
		}
		
		p.text__center{
			@include font-size(15);
	        text-align: center;
	        letter-spacing: 0px;
	        @include max-screen(767px) { 
	        	@include font-size(12);
	        }
		}
		
		

		.sec01{
			background-color: #f2f2f2;
			padding-bottom: 0;
			@include max-screen(767px) {
				
			}

		}
		.sec02{
			background-color: #f2f2f2;
			.row{
				max-width: 885px;
				margin: 0 auto;
				@include max-screen(767px) {
					margin: 0 -15px;
				}
				.col2{
					@include max-screen(767px) {
						margin-bottom: 15px;
						.sec02__img02{
							width: 46px;
						}
						.sec02__img03{
							width: 55px;
						}
						.sec02__img05{
							width: 49px;
						}
						.sec02__img04,.sec02__img06{
							width: 75px;
						}
						.sec02__img07{
							width: 79px;
						}
					}
				}
			}
			.image__center{
				text-align: center;
				img{
					margin: 0 auto 45px;
				}
				@include max-screen(767px) {
					margin-bottom: 15px;
				}
			}
			.image__middle{
				height: 100px;
				margin-bottom: 8px;

				@include max-screen(767px) {
					margin-bottom: 0px;
				}
				img{
					position: relative;
					top: 50%;
					-webkit-transform: translateY(-50%);
	                -ms-transform: translateY(-50%);
	                -o-transform: translateY(-50%);
	                transform: translateY(-50%);
					margin: 0 auto;
				}
			}
			p{
				text-align: center;
				line-height: 1.3;
			}

			
		}
		.sec03{
			background-color: #fffffe;
			margin-top: 5px;
			.row{ 
				max-width: 600px;
				margin: 0 auto;
				@include max-screen(400px) {
					margin: 0 -15px;
				}
				.col6{
					position: relative;
					&::after{
						background: url(../images/uketsuke/arrow_organe.png) 0 0 no-repeat;
						content: "";
						width: 18px;
						height: 21px;
						top: 39%;
						right: -8px;
						position: absolute;
						display: inline-block;
					}
					&:last-child{
						@include max-screen(767px) {
							margin-bottom: 0;
						}
						&::after{
							background: none;
						}
					}
					@include max-screen(767px) {
						width: 100%;
						text-align: center;
						margin-bottom: 40px;
						&::after{
							background: url(../images/uketsuke/arrow_organe2.png) 0 0 no-repeat;
							background-size: 100%;
							content: "";
							width: 24px;
							height: 24px;
							top: auto;
							bottom: -30px;
							left: 46%;
							position: absolute;
							display: inline-block;
						}
					}
				}
			}
			p.text__center{
				margin-top: 6px;
			}
			
		}
		.sec04{
			background-color: #f2f2f2;
			margin-top: 5px;
			.row{
				.col4{
					margin-bottom: 75px;
					position: relative;
					@include max-screen(767px) {
						
					}
					&:nth-child(1){
						left: 10px;
						@include max-screen(767px) {
							left: -10px;
							&::after{
								left: 47%;
							}
						}
					}
					&:nth-child(2){
						left: 29px;
						@include max-screen(767px) {
							left: 15px;
						}
						&::after{
							right: 42px;
							@include max-screen(767px) {
								left: 40%;
							}
						}
					}
					&:nth-child(3){
						@include max-screen(767px) {
							&::after{
								left: 47%;
							}
						}
					}
					&::after{
						background: url(../images/uketsuke/arrow_organe.png) 0 0 no-repeat;
						content: "";
						width: 18px;
						height: 21px;
						top: 46%;
						right: 16px;
						position: absolute;
						display: inline-block;
					}
					
					@include max-screen(767px) {
						width: 100%;
						text-align: center;
						margin-bottom: 40px;
						&::after{
							background: url(../images/uketsuke/arrow_organe2.png) 0 0 no-repeat;
							background-size: 100%;
							content: "";
							width: 24px;
							height: 24px;
							top: auto;
							bottom: -30px;
							left: 45%;
							position: absolute;
							display: inline-block;
						}
					}
				}

				&.row2{
					.col4{
						margin-bottom: 0;
						@include max-screen(767px) {
							margin-bottom: 40px;
						}
						&:nth-child(1){
							@include max-screen(767px) {
								&::after{
									left: 48%;
								}
							}
						}
						&:last-child{
							&::after{
								background: none;
							}
							@include max-screen(767px) {
								margin-bottom: 0;
								
							}
						}
					}
				}
			}
			p.text__center{
				margin-bottom: 25px;
				@include max-screen(767px) {
					margin-bottom: 0;
					
				}
			}
			
		}
		.sec05{
			background-color: #fffffe;
			margin-top: 5px;
			.row{
				max-width: 755px;
				margin: 0 auto;
				@include max-screen(767px) {
					margin: 0 -15px;
					.col6{
						&:last-child{
							.image__middle{
								margin-bottom: 0;
							}
						}
					}
					
				}
				.image__middle{
					margin-bottom: 17px;
					a{
						position: relative;
						display: block;
						height: 100%;
					}
					img{
						position: relative;
						top: 50%;
						-webkit-transform: translateY(-50%);
		                -ms-transform: translateY(-50%);
		                -o-transform: translateY(-50%);
		                transform: translateY(-50%);

						display: block;
						margin: 0 auto;
					}
					@include max-screen(767px) {
						height: auto;
						margin-bottom: 22px;
						float: none;
						img{
							top: auto;
							-webkit-transform: none;
							-ms-transform: none;
							-o-transform: none;
							transform: none;
							width: 100%;
						}
					}
				}
			}
			.floatR{
				float: right;

			}
		}
		
	}
	.row{
		margin-left: -15px;
		margin-right: -15px;
	}
	.row::after {
		display: block;
		content: "";
		clear: both;
	}
	.col2{
		width: 16.66%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 50%;
		}
	}
	.col4{
		width: 33.33%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 100%;
		}
	}
	.col6{
		width: 50%;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		@include max-screen(767px) {
			width: 100%;
		}
	}
	
	.hidden-xs {
		display: block;
		@include max-screen(767px){
			display: none;
		}
	}
	.visible-xs {
		display: none;
		@include max-screen(767px){
			display: block;
		}
	}
	
}
