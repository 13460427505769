#company {
	.sec01 {
		padding: 60px 0 40px;
		@include max-screen(767px) {
			padding: 30px 0 20px;
		}
		h2 {
			color: #000;
			text-align: center;
			@include font-size(30);
			margin: 0 0 25px;
			@include max-screen(767px) {
				@include font-size(16);
				margin-bottom: 10px;
			}
		}
		p {
			@include font-size(18);
			text-align: left;
			max-width: 720px;
			margin: 0 auto;
			line-height: 1.388em;
			@include max-screen(767px) {
				@include font-size(13);
				max-width: 480px;
			}
		}
	}
	h3 {
		background-color: #8fc31f;
		@include font-size(15);
		text-align: left;
		padding: 15px 30px;
		color: #fff;
		margin: 0 auto 30px;
		@include max-screen(767px) {
			margin: 0 -15px 15px;
			@include font-size(14);
			padding: 15px;
		}
	}
	.sec02,
	.sec03 {
		max-width: 800px;
		margin: 0 auto;
	}
	.sec02 {
		padding: 0 0 50px;
		@include max-screen(767px) {
			padding: 0 0 25px;
		}
		.table01 {
			max-width: 710px;
			width: 100%;
			margin: 0 auto;
			th,
			td {
				border: 1px solid #000;
				@include font-size(15);
				text-align: left;
				padding: 22px 10px;
				line-height: 1.133em;
				@include max-screen(767px) {
					@include font-size(12);
					line-height: 1.25em;
					padding: 22px 7px;
				}
			}
			th {
				background-color: #e5e5e5;
				width: 33%;
				@include max-screen(767px) {
					width: 28%;
				}
			}
			td {
				background-color: #fff;
				a{
					display: inline;
				}
			}
		}
	}
	.sec03 {
		padding: 0 0 75px;
		@include max-screen(767px) {
			padding: 0 0 30px;
		}
		.table02 {
			max-width: 710px;
			margin: 0 auto;
		}
		p {
			margin: 0 0 30px;
			@include font-size(18);
			@include max-screen(767px) {
				margin: 0 0 15px;
				@include font-size(12);
			}
		}
		.map {
			max-width: 450px;
			width: 100%;
			height: 435px;
			@include max-screen(767px) {
				height: 220px;
				width: 80%;
			}
		}
	}
}