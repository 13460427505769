body#recommend {
	.header__banner figure img{
		@include max-screen(767px) {
			width: 80%;
		}
		@include max-screen(480px) {
			width:230px;
		}
	}
	.footer__top{
		background-color: #fffffe;
	}
	#main{
		background-color: #f2f2f2;
		color: #010100;
		.container{
			max-width: 775px;		
		}
		h2.title{
			color: #000101;
	        @include font-size(30);
	        text-align: center;
	        background: url(../images/recommend/line_blue.jpg) bottom center repeat-x;
	        padding-bottom: 22px;
	        padding-top: 60px;
	        margin-bottom: 7px;
	        @include max-screen(767px) {
				@include font-size(15);
				padding-bottom: 20px;
	        	padding-top: 25px;
			}
		}
		.sec01{
			background: url(../images/recommend/line_green.png)  center 0 repeat-y;
			.sec01__arrow{
				background: url(../images/recommend/arrow_green.png) bottom center no-repeat;
				height: 16px;
				margin-bottom: 60px;
			}
			.sec01__box__white{
				@include font-size(16);
				background-color: #fff;
				padding: 30px 25px;
				margin-bottom: 60px;
				@include max-screen(767px) {
					padding: 15px;
					width: 90% !important;
				}
				&.box__left{
					width: 460px;
					float: left;
				}
				&.box__right{
					width: 572px;
					float: right;
				}
				&:last-child{
					width: 422px;
				}
				.content__vertical__image{
					float: left;
					text-align: center;
					width: 40%;
					padding-right: 40px;
					img{
						position: relative;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-o-transform: translateY(-50%);
						transform: translateY(-50%);

					}
					@include max-screen(767px) {
						padding-right: 15px;
					}

				}
				.content__vertical__text{
					width: 60%;
					float: left;	
					line-height: 1.8;
					position: relative;
					p{
						position: relative;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-o-transform: translateY(-50%);
						transform: translateY(-50%);
						letter-spacing: 0.02em;
					}
				}	
				&.last__item{
					.content__vertical__image{
						width: 30%;
					}
					.content__vertical__text{
						width: 70%;
					}
				}
				.sec01__box__grey{
					background-color: #f2f2f2;
					padding: 30px 5px 30px 25px;
					@include max-screen(767px) {
						padding: 15px 5px 15px 15px;
					}
					p{
						color: #1d2088;
						letter-spacing: 0.02em;
					}
					ul{
						margin-left: 15px;
						li {
					        list-style: none;
					        @include font-size(16);
					        color: #1d2088;
					        letter-spacing: 0.02em;
					        line-height: 30px;
					        @include max-screen(767px) {
								@include font-size(12.5);
								line-height: 24px;
							}
					    }
					    
					    li:before {
					        content:"・";
					        font-size:15px;
					        vertical-align:middle;
					        line-height:20px;
					        margin-left: -15px;
					    } 
					}
				}
				p.text__green{
					color: #8fc31f;
					padding: 20px 10px;
					@include max-screen(767px) {
						padding: 10px;
					}
				}
			}
			

		}
	}
	
}
