@charset 'UTF-8';
#gnavi {
    background: #8fc31f;
    @include min-screen(769px) {
        display: block !important;
        
    }
    @include max-screen(768px) {
        display: none;
        position: fixed;
        top: 60px;
        width: 100%;
        overflow-y: auto;
        z-index: 9999;
        
        background: none;
    }
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px) 
    and (orientation : landscape) { 
        height: 80vh;
        overflow-y: scroll;
    }
    >ul {
        max-width: 950px;
        margin: 0 auto;
        @include pie-clearfix();
        >li {
            float: left;
            border-right: 1px solid #fff;
            text-align: center;
            @include min-screen(769px) {
                width: 25%;
                min-width: auto !important;
                min-width: initial !important;
                
            }
            @include min-screen(1000px) {
                &:nth-child(1),
                &:nth-child(4) {
                    min-width: 250px;
                }
                &:nth-child(2),
                &:nth-child(3) {
                    min-width: 212px;
                }
            }
            @include max-screen(768px) {
                width: 100%;
                float: none;
                border-right: none;
                text-align: left;
                border-bottom: 1px solid #fff;
                background: #8fc31f;
                &:first-child {
                    border-top: 1px solid #618415;
                }
            }
            &.sub {
                position: relative;
            }
            &:last-child {
                border-right: none;
            }
            a {
                color: #fff;
                font-weight: normal;
                padding: 12px 0;
                display: block;
                &:hover {
                    background-color: #c3d824;
                    @include max-screen(768px) {
                        background-color: #8fc31f;
                    }
                }
                &.active {
                    background-color: #c3d824;
                    @include max-screen(767px) {
                        background-color: #8fc31f;
                    }
                }
                @include min-screen(769px) {
                    @include font-size(14);
                }
                @include min-screen(1000px) {
                    @include font-size(20);
                }
                @include max-screen(768px) {
                    font-size: 15px !important;
                    padding: 20px;
                    box-sizing: border-box;
                    -webkit-transition: none;
                    -moz-transition: none;
                    -o-transition: none;
                    transition: none;
                    -webkit-text-size-adjust: 100%;
                }
            }
        }
    }
    .sub_menu {
        display: none;
        position: absolute;
        z-index: 9999;
        top: 100%;
        left: 0;
        width: 100%;
        @include max-screen(768px) {
            position: relative;
        }
        >ul {
            >li {
                text-align: left;
                border-bottom: 1px solid #fff;
                a {
                    padding: 12px 15px;
                    @include font-size(18);
                    background-color: #c3d824;
                    @include max-screen(767px) {
                        font-size: 15px !important;
                        padding: 20px 30px;
                        -webkit-transition: none;
                        -moz-transition: none;
                        -o-transition: none;
                        transition: none;
                    }
                    &:hover {
                        background-color: #ffa500;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

#gnav-toggle {
    display: none;
    cursor: pointer;
    @include min-screen(769px) {
        display: none !important;
    }
    @include max-screen(768px) {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 70px;
        height: 59px;
        border: 0;
        outline: 0;
        z-index: 2000;
        /*@include transition(all, .2s);*/
        @include translate(0, 0);
        background: transparent;
    }
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 4px;
        background: #8fc31f;
        opacity: 1;
        @include translate(-50%, -50%);
    }
    &::before,
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        display: block;
        width: 30px;
        height: 4px;
        background: #8fc31f;
        margin: -1px 0 0 -15px;
    }
    &::before {
        @include translateY(-10px);
    }
    &::after {
        @include translateY(8px);
    }
    &.opened {
        display: block;
        z-index: 9999;
        background-color: #8fc31f !important;
        span {
            display: block;
            background-color: #fff;
        }
        &::before {
            background-color: #fff;
        }
        &::after {
            background-color: #fff;
        }
    }
}
