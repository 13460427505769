// Author: Kyohei Maeda
//==================================================
@charset 'UTF-8';

// Web Fonts
//------------------------------
@import "utility/google-fonts";
//@import "utility/font-awesome/font-awesome";

// Frame Works
//------------------------------
//@import "utility/bootstrap/bootstrap";


// Custom Parts
//------------------------------
@import "utility/mixin";
@import "utility/media-queries";
@import "utility/heading";
@import "utility/table";
@import "utility/link";
@import "utility/button";
//@import "utility/slick";
//@import "utility/slick-theme";
@import "utility/_animate";
// @import "utility/_owl.carousel";
// @import "utility/_owl.theme.default";
