@charset 'UTF-8';
#top {
    h2 {
        color: #1d2088;
        @include font-size(30);
        text-align: center;
        margin: 0 0 35px;
        @include max-screen(767px) {
            @include font-size(20);
            margin: 0 0 20px;
        }
    }
    #main {
        .sec01 {
            padding: 60px 0;
            @include max-screen(767px) {
                padding: 30px 0 10px;
            }
            &__txt {
                @include font-size(18);
                line-height: 1.777em;
                text-align: left;
                max-width: 730px;
                margin: 0 auto;
                padding: 0 15px;
                @include max-screen(767px) {
                    @include font-size(14);
                }
            }
            &__list {
                @include pie-clearfix();
                padding: 10px 0 0;
                &--item {
                    width: 50%;
                    float: left;
                    text-align: center;
                    @include max-screen(767px) {
                        width: 100%;
                        padding: 35px 19px !important;
                    }
                    &:nth-child(even) {
                        padding: 65px 105px 10px 40px;
                    }
                    &:nth-child(odd) {
                        padding: 65px 40px 10px 105px;
                    }
                    figure {
                        margin: 0 0 25px;
                    }
                    h3 {
                        color: #1d2088;
                        @include font-size(20);
                        margin: 0 0 15px;
                        @include max-screen(767px) {
                            @include font-size(16);
                        }
                    }
                    p {
                        @include font-size(15);
                        text-align: left;
                        line-height: 1.666em;
                        margin: 0 0 15px;
                        @include max-screen(767px) {
                            position: relative;
                            @include font-size(12);
                            width: 70%;
                            margin: 0 auto 15px;
                            span.center__block{
                                left: 50%;
                                -webkit-transform: translateX(-50%);
                                -ms-transform: translateX(-50%);
                                -o-transform: translateX(-50%);
                                transform: translateX(-50%);
                                display: inline-block;
                                margin: 0 auto;
                                position: relative;
                            }
                            @include max-screen(320px) {
                                width: 100%;
                            }
                        }
                        &.pd_l1 {
                            @include max-screen(768px) {
                                br{display:none;}
                            }
                        }
                        &.pd_l2 {
                            padding: 0 0 0 14px;
                            @include max-screen(768px) {
                                padding: 0;
                                br{display:none;}
                            }
                        }
                        &.pd_l4 {
                            padding: 0 0 0 30px;
                            @include max-screen(768px) {
                                padding: 0;
                            }
                        }
                    }
                    a {
                        color: #fff;
                        background-color: #45ada8;
                        @include font-size(14);
                        padding: 14px 0;
                        max-width: 230px;
                        margin: 0 auto;
                        @include max-screen(767px) {
                            width: 65%;
                            max-width: 100%;
                            @include font-size(12);
                        }
                        &:hover {
                            background-color: #4ec4be;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .sec02 {
            &__top {
                padding: 50px 0 30px;
                background-color: #fffffe;
                h2 {
                    margin: 0 0 25px;
                }
                &__txt {
                    max-width: 830px;
                    @include font-size(18);
                    margin: 0 auto;
                    padding: 0 15px;
                    @include max-screen(767px) {
                        @include font-size(14);
                        max-width: 100%;
                        padding: 0 30px;
                        line-height: 1.5em;
                    }
                }
            }
            &__step {
                background-color: #e1e4e3;
                padding: 30px 0 25px;
                &--item {
                    @include pie-clearfix();
                    margin: 0 -12px;
                    padding: 0 40px;
                    li {
                        float: left;
                        width: 14.2857%;
                        padding: 0 12px;
                        text-align: center;
                        position: relative;
                        @include max-screen(767px) {
                            width: 100%;
                            float: none;
                            text-align: center;
                            margin: 0 auto 40px;
                            max-width: 172px;
                        }
                        &:before {
                            content: '';
                            background: url(../images/top/arrow_pc.png) no-repeat right center;
                            background-size: 15px 16px;
                            position: absolute;
                            top: 50%;
                            right: -8px;
                            width: 15px;
                            height: 16px;
                            margin-top: -25px;
                            @include max-screen(767px) {
                                background: url(../images/top/arrow_sp.png) no-repeat bottom center;
                                background-size: 28px 27px;
                                top: inherit;
                                bottom: -35px;
                                right: 0;
                                width: 28px;
                                height: 27px;
                                left: 50%;
                                margin-left: -14px;
                            }
                        }
                        &:last-child {
                            @include max-screen(767px) {
                                margin: 0 auto;
                            }
                            &:before {
                                display: none;
                            }
                        }
                        span {
                            @include font-size(15);
                            padding: 10px 0 0;
                            display: inline-block;
                            @include max-screen(768px) {
                                @include font-size(12);
                            }
                            &.mar_r {
                                margin: 0 -25px 0 -15px;
                                @include max-screen(767px) {
                                    margin: 0;
                                }
                            }
                        }
                        img{
                            image-rendering: -moz-crisp-edges;         /* Firefox */
                            image-rendering:   -o-crisp-edges;         /* Opera */
                            image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
                            image-rendering: crisp-edges;
                            -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
                        }
                    }
                }
            }
            &__map {
                margin: 60px auto 35px;
                background-color: #fffffe;
                max-width: 610px;
                @include border-radius(7px);
                @include max-screen(767px) {
                    margin: 30px auto;
                    width: 95%;
                }
                &--item {
                    padding: 65px 30px 30px 50px;
                    @include pie-clearfix();
                    @include max-screen(767px) {
                        padding: 25px 15px 15px 20px;
                        width: 320px;
                        margin: 0 auto;
                    }
                    h3 {
                        @include font-size(24);
                        text-align: left;
                        line-height: 1.1666em;
                        @include max-screen(767px) {
                            @include font-size(12);
                            line-height: 1.4em;
                        }
                    }
                    figure {
                        float: right;
                        margin-top: -55px;
                        @include max-screen(767px) {
                            width: 70%;
                            margin-top: -30px;
                        }
                    }
                }
            }
        }
    }
    .footer {
        &__top {
            background-color: #fffffe;
        }
    }
}
