// Author: Kyohei Maeda
// modify by Kyohei Maeda 20160812
//==================================================
@charset 'UTF-8';

//==================================================
// base
//==================================================
$gothic: Meiryo, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
html {
	font-size: 62.5%; // Sets up the Base 10 stuff
	font-family: Meiryo, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
	overflow-x: hidden;
}
body {
	font-family: Meiryo, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
	@include font-size(14);
	overflow-x: hidden;
	color: #000000;
	background: #f9f9f9;
}
h1,h2,h3,h4,h5 {
	margin: 0 auto;
	padding: 0;
}
img {
	max-width: 100%;
	height: auto;
}
a {
	display: block;
	@include transition(all .3s);
	text-decoration: none;
	color: #000000;
	outline: none;
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	&:active,
	&:focus,
	&:visited {
		text-decoration: none;
	}
}
p {
	line-height: 1.6;
	letter-spacing: 0.05em;
	@include max-screen(768px) {
		@include font-size(12);
		line-height: 2;
	}
}
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
html {
  @include box-sizing(border-box);
}
*, *:before, *:after {
  box-sizing: inherit;
}
.container{
	padding: 0 15px;
	display: block;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
	@media(min-width: 768px){
		max-width: 950px;
	}

	@media(max-width: 767px){
		width: 100%;
	}
}
.clearfix:after {
    content: '';
    display: block;
    clear: both;
}
li {
    list-style:none;
}
.hidden-xs {
	display: inline-block;
	@include max-screen(767px) {
		display: none;
	}
}
.visible-xs {
	display: none;
	@include max-screen(767px) {
		display: inline-block;
	}
}
// Mixin
@mixin transition-timing($radius) {
	  -webkit-transition: all $radius cubic-bezier(0.600, 0.040, 0.980, 0.335);
		 -moz-transition: all $radius cubic-bezier(0.600, 0.040, 0.980, 0.335);
		   -o-transition: all $radius cubic-bezier(0.600, 0.040, 0.980, 0.335);
		   	  transition: all $radius cubic-bezier(0.600, 0.040, 0.980, 0.335);
-webkit-transition-timing-function: cubic-bezier(0.600, 0.040, 0.980, 0.335);
   -moz-transition-timing-function: cubic-bezier(0.600, 0.040, 0.980, 0.335);
	 -o-transition-timing-function: cubic-bezier(0.600, 0.040, 0.980, 0.335);
	    transition-timing-function: cubic-bezier(0.600, 0.040, 0.980, 0.335);
}